import React from "react";
import axios from "axios";
import './custom/style.css';
const api = axios.create({
  baseURL: 'https://data.covid19india.org'
})
class CoronaStateWise extends React.Component {

  state = {
    notices:[]
  }

  constructor() {
    super();
    api.get('/data.json').then((res,data) =>{
      console.log(res,data)
      this.setState({ notices: res.data.statewise})
    });

    
  } 
  render() {
    return(
      <div>
        <h1 align="center">INDIA</h1>
        <div class="table-responsive">
        
        <table class="table text-light table-bordered table-triped text-center">
        <tr>
            <th>State</th>
            <th>Active</th>
            <th>Confirmed</th>
            <th>Deaths</th>
            <th>Deltaconfirmed</th>
            <th>Deltadeaths</th>
            <th>Deltarecovered</th>
            <th>Lastupdatedtime</th>
            <th>Migratedother</th>
            <th>Recovered</th>
            <th>Rtatecode</th>
            <th>Statenotes</th>
           
          </tr>
        {
          this.state.notices!=null
          ?
          this.state.notices.map(
            notice=>
        
         
          <tr>
            <th class="state">{notice.state}</th>
            <th class="time">{notice.active}</th>
            <th class="state">{notice.confirmed}</th>
            <th class="confirmed">{notice.deaths}</th>
            <th class="active">{notice.deltaconfirmed}</th>
            <th class="recovered">{notice.deltadeaths}</th>
            <th class="dconfirm">{notice.deltarecovered}</th>
            <th class="ddeaths">{notice.lastupdatedtime}</th>
            <th class="drecoverd">{notice.migratedother}</th>
            <th class="confirmed">{notice.recovered}</th>
            <th class="drecoverd">{notice.statecode}</th>
            <th class="recovered">{notice.statenotes}</th>
          </tr>
        
             )
          :
          <h2>error</h2>
         
        }
        </table>
        </div>
      </div>
    );
  }
}

export default CoronaStateWise;
/*import axios from "axios";
import React from "react";

const baseURL = "https://jsonplaceholder.typicode.com/posts/1";

export default function App() {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data);
    });
  }, []);

  if (!post) return null;

  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.body}</p>
    </div>
  );
}*/
/*
import axios from "axios";
import React from "react";

const baseURL = "https://jsonplaceholder.typicode.com/posts";

export default function App() {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(`${baseURL}/1`).then((response) => {
      setPost(response.data);
    });
  }, []);

  function createPost() {
    axios
      .post(baseURL, {
        title: "Hello World!",
        body: "This is a new post."
      })
      .then((response) => {
        setPost(response.data);
      });
  }

  if (!post) return "No post!"

  return (
    <div>
      <h1>{post.title}</h1>
      <p>{post.body}</p>
      <button onClick={createPost}>Create Post</button>
    </div>
  );
}*/
/*import { useAxios } from "use-axios-client";

export default function App() {
  const { data, error, loading } = useAxios({
    url: "https://agsasn.aestree.com/api/notices/608aa8028c848"
  });

  if (loading || !data) return "Loading...";
  if (error) return "Error!";

  return (
    <div>
      <h1>{data.title}</h1>
      <p>{data.message}</p>
    </div>
  ) 
}*/

import React from "react";
import axios from "axios";
import './custom/style.css';
const api = axios.create({
  baseURL: 'https://data.covid19india.org'
})
class Corona extends React.Component {

  state = {
    notices:[]
  }

  constructor() {
    super();
    api.get('/data.json').then((res,data) =>{
      console.log(res,data)
      this.setState({ notices: res.data.cases_time_series})
    });

    
  } 
  render() {
    return(
      <div>
        <h1 align="center" font-family="Alfa Slab One', cursive">INDIA</h1>
        <div class="table-responsive">
        
        <table class="table text-light table-bordered table-triped text-center">
        <tr>
            <th>Daily confirmed</th>
            <th>Daily deceased</th>
            <th>Daily recovered</th>
            <th>Date</th>
            <th>Date (YY/MM/DD)</th>
            <th>Total confirmed</th>
            <th>Total deceased</th>
            <th>Total recovered</th>
          </tr>
        {
          this.state.notices!=null
          ?
          this.state.notices.map(
            notice=>
        
         
          <tr>
            <th class="time">{notice.dailyconfirmed}</th>
            <th class="state">{notice.dailydeceased}</th>
            <th class="confirmed">{notice.dailyrecovered}</th>
            <th class="active">{notice.date}</th>
            <th class="recovered">{notice.dateymd}</th>
            <th class="dconfirm">{notice.totalconfirmed}</th>
            <th class="ddeaths">{notice.totaldeceased}</th>
            <th class="drecoverd">{notice.totalrecovered}</th>
          </tr>
        
             )
          :
          <h2>error</h2>
         
        }
        </table>
        </div>
      </div>
    );
  }
}

export default Corona;



//import logo from './logo.svg';
import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom/style.css';
import Cor from './Corona';
import CSW from './CoronaStateWise';
import H from'./Header';
import B from'./Body';
import F from'./Footer';

function Home() {
  return <h2>Home</h2>;
}

function About() {
  return <h2>About</h2>;
}
function Users() {
  return <h2>Users</h2>;
}

function App() {
  return (
    <Router>
      <div>
        <H />
        {/*<nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav>*/}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/corona">
              <Cor />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/coronastatewise">
              <CSW />
            </Route>
          </Switch>
        
          </div> 
        <F />
    </Router>
    
    
  );
}

export default App;

//import { Row} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, NavLink } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'font-awesome/css/font-awesome.min.css';
import { faUser, faBan } from '@fortawesome/free-solid-svg-icons';
import './custom/style.css';
function Nav() {
    return(
    <header>
        <img class="logo" src="https://picsum.photos/200/50" alt="Site Logo"/>
        
        <nav>
            <ul class="nav__links">
            <li><NavLink class="cta"  as={Link} to="/home" href="/home">Home</NavLink></li>
            <li><NavLink as={Link} to="/about" href="/about">About</NavLink></li>
            <li><NavLink as={Link} to="/corona" href="/corona">Corona Updates</NavLink></li>
            <li><NavLink as={Link} to="/coronastatewise" href="/coronastatewise">Corona State Wise</NavLink></li>
            
            </ul>
        </nav>
    </header>
    );
}
export default Nav;

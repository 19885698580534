//import { Row} from 'react-bootstrap';
import Navigation from './Nav';
import './custom/style.css';

function Header() {
    return(
        <Navigation />
    );
}

export default Header;